import React from "react";
import styled from "styled-components";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 50px;
`;

const DescriptionTitle = styled.h2`
  font-size: 3.5vw;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 6vw;
  }
`;

const DescriptionText = styled.h3`
  font-size: 2.2vw;
  margin-top: 10px;
  font-weight: normal;
  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const Form = styled.form`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1.8vw;
  margin-bottom: 5px;
`;

const Fieldset = styled.fieldset`
  border: none;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  resize: vertical; /* Restringe la expansión horizontal */
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  color: #fff;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  background-color: rgb(23, 4, 49);
  &:hover {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
`;

const Contact = (props) => {
    return (
        <ContactContainer id="contact">
            <DescriptionTitle>Contact</DescriptionTitle>
            <DescriptionText>Want to know more?</DescriptionText>
            <Form action="Aquí va el sitio donde se debe enviar el formulario">
                <Fieldset>
                    <Label htmlFor="writeus">Write us</Label>
                </Fieldset>
                <Fieldset>
                    <Input type="email" name="email" id="email" placeholder="Email" />
                </Fieldset>
                <Fieldset>
                    <Input type="text" name="subject" id="subject" placeholder="Subject" />
                </Fieldset>
                <Fieldset>
                    <TextArea
                        name="comments"
                        rows="8"
                        id="comments"
                        placeholder="Comments"
                    ></TextArea>
                </Fieldset>
                <Fieldset>
                    <SubmitButton type="submit">Submit</SubmitButton>
                </Fieldset>
            </Form>
        </ContactContainer>
    );
};

export default Contact;
