import React from "react";
import demo from '../../assets/imgDemo.jpeg';
import {DEMO_APP_URL} from "../../constants";
import styled from "styled-components";

const DescriptionContainer = styled.div`
  background-color: rgb(23, 4, 49);
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
`;

const DescriptionTitle = styled.h1`
  font-size: 4.5vw;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin-bottom: 10px;
`;

const DescriptionText = styled.h2`
  font-size: 3.5vw;
  font-weight: 500;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Image = styled.img`
  width: 80%;
  max-width: 900px;
  height: auto;
  border-radius: 10px;
`;

const DemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const DemoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 10px; /* Agregamos un espacio vertical entre los elementos */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const H3Demo = styled.h3`
  font-size: 2.2vw;
  margin-top: 10px;
  margin-bottom: 10px; /* Agregamos un margen inferior */
  font-weight: normal;
  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const ButtonDemo = styled.a`
  font-size: 1.5vw;
  padding: 10px 20px;
  text-align: center;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  background-color: rgb(23, 4, 49);
  border: 1px solid white;
  white-space: nowrap;
  transition: background-color 0.3s;
  display: inline-block; /* Asegura que el botón se ajuste al contenido */
  vertical-align: middle; /* Alinea verticalmente con el texto */

  &:hover {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }

  @media (max-width: 768px) {
    font-size: 3.5vw;
    padding: 10px 15px;
    margin-top: 20px;
  }
`

const Description = (props) => {
    return (
        <DescriptionContainer>
            <div>
                <DescriptionTitle>ClubTrack</DescriptionTitle>
                <DescriptionText>All your club data<br/>in one place</DescriptionText>
            </div>
            <ImageContainer>
                <Image src={demo} alt="dashboard-image"/>
            </ImageContainer>
            <DemoContainer>
                <DemoContent>
                    <H3Demo>Try our dashboard online</H3Demo>
                    <ButtonDemo href={DEMO_APP_URL} target="_blank" id="demo" rel="noopener noreferrer">
                        Demo
                    </ButtonDemo>
                </DemoContent>
            </DemoContainer>
        </DescriptionContainer>
    );
};

export default Description;