import styled from "styled-components";
import PricingCard from "./PricingCard";

const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`

const DescriptionTitle = styled.h2`
  font-size: 3.5vw;
  font-weight: 500;
`;

const DescriptionText = styled.h3`
  font-size: 2.2vw;
  margin-top: 10px;
  font-weight: normal;
  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

function Pricing() {
    return (
        <PricingContainer id="pricing">
            <DescriptionTitle>Pricing</DescriptionTitle>
            <DescriptionText>Choose your plan</DescriptionText>
            <MainContainer>
                <div>
                    <PricingCard
                        data={[
                            {text: "Up to 1GB of data", value: true},
                            {text: "Up to 1 user", value: true},
                        ]}
                        price={499}
                        duration="m"
                        currency="$"
                        buttonContent="Sign Up"
                        // subTitle="Great for starters"
                        priceText="For clubs that want to explore their data"
                        headerText="starter"
                    />
                </div>
                <div>
                    <PricingCard
                        data={[
                            {text: "Up to 5GB of data", value: true},
                            {text: "Up to 5 users", value: true},
                            {text: "Up to 5 integrations", value: true},
                        ]}
                        price={999}
                        duration="m"
                        // background="linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)"
                        shadow="#96e6a1"
                        currency="$"
                        buttonContent="Sign Up"
                        // subTitle="For Planned Projects"
                        priceText="For clubs that want to monetize their data"
                        headerText="basic"
                    />
                </div>
                <div>
                    <PricingCard
                        data={[
                            {text: "Up to 20GB of data", value: true},
                            {text: "Unlimited users", value: true},
                            {text: "Unlimited integrations", value: true},
                            {text: "Tech support 24/7", value: true},
                        ]}
                        price={1499}
                        duration="m"
                        // background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);"
                        shadow="#ffb199"
                        currency="$"
                        buttonContent="Sign Up"
                        // subTitle="For Prodessional Use"
                        priceText="For clubs that want to revolutionize their data"
                        headerText="pro"
                    />
                </div>
            </MainContainer>
        </PricingContainer>
    );
}

const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  //padding: 30px;
  //background-color: #f5f1ff;

  @media screen and (max-width: 1111px) {
    height: 100%;
    flex-direction: column;
    .pricing-component {
      margin: 2rem 0;
    }
  }
`;

export default Pricing;