import React from "react";
import {DOCS_URL, SIGN_IN_URL, SIGN_UP_URL, WEBSITE_URL} from "../../constants";
import logo from '../../assets/clubtrack-logo.png';
import styled from "styled-components";
import PropTypes from "prop-types";

const NavBarContainer = styled.nav`
  font-family: 'Inter', sans-serif;
  padding: 30px 100px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1112px) {
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    flex-direction: row;
  }
`

const LogoContainer = styled.a`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  display: inline-block;
  width: 170px;
  @media (min-width: 1112px) {
    width: 200px;
  }
`

const MainNav = styled.ul`
  list-style-type: none;
  display: ${props => props.display};
  flex-direction: column;
  padding: 0px;
  @media (min-width: 1112px) {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
  }
`

const NavLi = styled.li`
  text-align: center;
  padding: 0px;
  margin: 0px;
`

const NavLink = styled.a`
  font-size: 18px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  padding: 5px 10px;
  @media (min-width: 1112px) {
    margin: 0px 10px;
    color: white;
    text-decoration: none;
  }
`

const LoginNavLink = styled(NavLink)`
  border: 1px solid white;
  border-radius: 10px;
  max-width: 100px;
  margin: auto;

  @media (max-width: 1111px) {
    max-width: 100px;
    margin: 10px auto;
  }

  &:hover {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
`;

const RegisterNavLink = styled(NavLink)`
  border: 1px solid white;
  border-radius: 10px;
  background-color: white;
  color: rgb(23, 4, 49);
  
  @media (max-width: 1111px) {
    max-width: 100px;
    margin: 10px auto;
  }
  
  &:hover {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
`;

const NavBarToggle = styled.span`
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
`
const Hamburger = styled.img`
  /* add your menu icon here i.e. */
  /* content: url('../static/Hamburger_icon.svg'); */
  content: url(data:image/svg+xml,%3Csvg%20height%3D%2232px%22%20id%3D%22Layer_1%22%20style%3D%22enable-background%3Anew%200%200%2032%2032%3B%22%20version%3D%221.1%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232px%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cpath%20d%3D%22M4%2C10h24c1.104%2C0%2C2-0.896%2C2-2s-0.896-2-2-2H4C2.896%2C6%2C2%2C6.896%2C2%2C8S2.896%2C10%2C4%2C10z%20M28%2C14H4c-1.104%2C0-2%2C0.896-2%2C2%20%20s0.896%2C2%2C2%2C2h24c1.104%2C0%2C2-0.896%2C2-2S29.104%2C14%2C28%2C14z%20M28%2C22H4c-1.104%2C0-2%2C0.896-2%2C2s0.896%2C2%2C2%2C2h24c1.104%2C0%2C2-0.896%2C2-2%20%20S29.104%2C22%2C28%2C22z%22%2F%3E%3C%2Fsvg%3E);
  filter: invert(1);
  padding: 20px;
  @media (min-width: 1112px) {
    display: none;
  }
`


export class Navbar extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {displayNav: (props.displayNav ? 'flex' : 'none')};
    }

    toggleNavBar() {
        this.setState((prevState, props) => {
            switch (prevState.displayNav) {
                case 'none':
                    return {displayNav: 'flex'};
                case 'flex':
                default:
                    return {displayNav: 'none'}
            }
        })
    }

    render() {
        return (
            <NavBarContainer>
                <NavBarToggle onClick={() => this.toggleNavBar()}>
                    <Hamburger/>
                </NavBarToggle>
                <LogoContainer href={WEBSITE_URL}>
                    <Logo src={logo} alt="ClubTrack Logo"/>
                </LogoContainer>
                <MainNav display={this.state.displayNav}>
                    <NavLi>
                        <NavLink href="#pricing">Pricing</NavLink>
                    </NavLi>
                    <NavLi>
                        <NavLink href="#">Features</NavLink>
                    </NavLi>
                    <NavLi>
                        <NavLink href="#">FAQ</NavLink>
                    </NavLi>
                    <NavLi>
                        <NavLink href="#">About Us</NavLink>
                    </NavLi>
                    <NavLi>
                        <NavLink href="#contact">Contact</NavLink>
                    </NavLi>
                    <NavLi>
                        <NavLink href={DOCS_URL}>Docs</NavLink>
                    </NavLi>
                    <NavLi>
                        <LoginNavLink href={SIGN_IN_URL}>Login</LoginNavLink>
                    </NavLi>
                    <NavLi>
                        <RegisterNavLink href={SIGN_UP_URL}>Register</RegisterNavLink>
                    </NavLi>
                </MainNav>
            </NavBarContainer>
        )
    };
}

Navbar.propTypes = {
    displayNav: PropTypes.bool,
}

export default Navbar;