import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Description from '../pages/components/Description';
import Pricing from "../pages/components/Pricing";
import styled from "styled-components";
import Contact from "../pages/components/Contact";

const AppContainer = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
`

function App() {
    return (
        <AppContainer>
            <Navbar/>
            <Description/>
            <Pricing/>
            <Contact></Contact>
            {/*<BookACall></BookACall>*/}
            {/*<Footer></Footer>*/}
        </AppContainer>
    );
}

export default App;
